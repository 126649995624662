'use strict'

const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

function patchColumn(pageJson, cache, mobileView, comp) {
    if (mobileView && _.startsWith(comp.designQuery, '#mobile_')) {
        this.deleteComponentDesignData(pageJson, comp)
        comp.designQuery = _.replace(comp.designQuery, 'mobile_', '')
    }
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.Column': patchColumn
}

module.exports = {exec: createHelperAndMigratePage(MIGRATION_MAP)}
