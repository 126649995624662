'use strict'
const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const createTransparentContainerStyle = () => ({
    type: 'ComponentStyle',
    styleType: 'custom',
    metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false
    },
    style: {
        groups: {},
        properties: {
            'alpha-bg': 0,
            'alpha-brd': '1',
            bg: 'rgba(50,50,50,0)',
            'boxShadowToggleOn-shd': 'false',
            brd: '#ffffff',
            brw: '0px',
            rd: '0px',
            shd: '0px 0px 0px 0px rgba(0,0,0,0)'
        },
        propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value'
        }
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin'
})

const STUFF_PAGE_HAS_WHICH_CONTAINER_DOESNT = ['dataQuery', 'designQuery', 'propertyQuery', 'connectionQuery', 'mobileComponents', 'mobileHintsQuery']
const migratePageToContainer = pageComp => {
    Object.assign(pageComp, {
        type: 'Container',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
        componentType: 'mobile.core.components.Container'
    })
    STUFF_PAGE_HAS_WHICH_CONTAINER_DOESNT.forEach(key => {
        delete pageComp[key]
    })
}

const deletePage = (comp, parentCompsArray) => {
    parentCompsArray.splice(parentCompsArray.indexOf(comp), 1)
}

const convertMobilePageToContainer = (comp, cachedStyleId) => {
    comp.styleId = cachedStyleId
    migratePageToContainer(comp)
}

function convertDesktopPageToContainer(pageJson, cache, mobileView, comp) {
    delete comp.styleId
    this.setComponentStyle(pageJson, comp, createTransparentContainerStyle(), cache)
    migratePageToContainer(comp)
}

function handleNestedPage(pageJson, cache, mobileView, comp, uniqueIdGenerator, parentCompsArray) {
    if (!comp.components.length) {
        deletePage(comp, parentCompsArray)
        return
    }
    if (!mobileView) {
        convertDesktopPageToContainer.call(this, pageJson, cache, mobileView, comp)
        return
    }
    const cachedStyleId = _.get(cache, [comp.id, 'styleId'])
    if (!cachedStyleId) {
        // We should never get here, but if there is no corresponding desktop component delete from mobile as well
        deletePage(comp, parentCompsArray)
        return
    }
    convertMobilePageToContainer(comp, cachedStyleId)
}

module.exports = {
    exec: createHelperAndMigratePage({
        'mobile.core.components.Page': handleNestedPage
    })
}
