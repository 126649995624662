'use strict'

const _ = require('lodash')

const TPA_GLUED_WIDGET_COMP_TYPE = 'wysiwyg.viewer.components.tpapps.TPAGluedWidget'
const WIX_CHAT_APPDEF_ID = '14517e1a-3ff0-af98-408e-2bd6953c36a2'
const WIDGET_ID = '14517f3f-ffc5-eced-f592-980aaa0bbb5c'

const MASTER_PAGE_ID = 'masterPage'

function setComponentData(pageJson, comp, dataItem, uniqueIdGenerator) {
    const newId = uniqueIdGenerator.getUniqueId('dataItem', '-')

    comp.dataQuery = `#${newId}`
    dataItem.id = newId
    pageJson.data.document_data[newId] = dataItem
}

function addWixChatToDesktopStructure(clientSpecMap, pageJson, uniqueIdGenerator) {
    const appData = _.find(clientSpecMap, {appDefinitionId: WIX_CHAT_APPDEF_ID})
    if (appData) {
        const widgetDataItems = getWidgetsCompAndData(pageJson, appData.applicationId)
        _.forEach(widgetDataItems, widget => {
            const widgetData = widget.data
            const widgetIds = _.keys(appData.widgets)
            if (_.toString(widgetData.applicationId) !== _.toString(appData.applicationId) || !_.includes(widgetIds, widgetData.widgetId)) {
                setComponentData(
                    pageJson,
                    widget.comp,
                    _.assign(widgetData, {
                        applicationId: _.toString(appData.applicationId),
                        widgetId: WIDGET_ID
                    }),
                    uniqueIdGenerator)
            }
        })

        if (!isAppPermissionsRevoked(appData) && !widgetDataItems.length) {
            const widgetData = _.get(appData, `widgets.${WIDGET_ID}`)
            const compId = uniqueIdGenerator.getUniqueId('comp', '-')
            const dataId = uniqueIdGenerator.getUniqueId('dataItem', '-')
            const propId = uniqueIdGenerator.getUniqueId('propItem', '-')
            const widgetStructure = getWidgetStructure(compId, dataId, propId, widgetData)
            pageJson.structure.children.push(widgetStructure)
            pageJson.data.document_data[dataId] = getWidgetData(appData, dataId)
            pageJson.data.component_properties[propId] = getWidgetProperties(widgetData)
            if (!pageJson.data.theme_data[widgetStructure.styleId]) {
                pageJson.data.theme_data[widgetStructure.styleId] = getGluedWidgetStyle(widgetStructure.styleId)
            }
        }
    }
}

function getWidgetStructure(compId, dataId, propId, widgetLayoutData, styleId) {
    return {
        type: 'Component',
        styleId: styleId || 'tpagw0',
        id: compId,
        dataQuery: `#${dataId}`,
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
            width: _.get(widgetLayoutData, 'defaultWidth') || 320,
            height: _.get(widgetLayoutData, 'defaultHeight') || 250,
            x: _.get(widgetLayoutData, 'x') || 0,
            y: _.get(widgetLayoutData, 'y') || 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: _.get(widgetLayoutData, 'fixedPosition') || true
        },
        propertyQuery: propId,
        componentType: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget'
    }
}

function getWixChatGluedTPAStructure(desktopId, dataItemQuery, propertyItemQuery, styleId) {
    dataItemQuery = removeHashIfExists(dataItemQuery)
    propertyItemQuery = removeHashIfExists(propertyItemQuery)
    const widgetLayoutData = {
        x: 10, y: 10,
        width: 0, height: 5,
        fixedPosition: false
    }
    return getWidgetStructure(desktopId, dataItemQuery, propertyItemQuery, widgetLayoutData, styleId)
}

function getWidgetData(appData, dataId) {
    return {
        type: 'TPAWidget',
        id: dataId,
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        },
        applicationId: _.toString(appData.applicationId),
        widgetId: WIDGET_ID
    }
}

function getWidgetProperties(widgetData) {
    return {
        type: 'TPAGluedProperties',
        metaData: {
            schemaVersion: '1.0'
        },
        placement: _.get(widgetData, 'gluedOptions.placement'),
        verticalMargin: _.get(widgetData, 'gluedOptions.verticalMargin'),
        horizontalMargin: _.get(widgetData, 'gluedOptions.horizontalMargin')
    }
}

function getGluedWidgetStyle(styleId) {
    return {
        type: 'TopLevelStyle',
        styleType: 'system',
        compId: '',
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        pageId: '',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        style: {
            groups: {},
            properties: {},
            propertiesSource: {}
        },
        id: styleId,
        metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false
        }
    }
}

function getWidgetsCompAndData(pageJson, applicationId) {
    return _(pageJson.structure.children).filter(comp => {
        if (!comp.dataQuery) {
            return false
        }
        const data = pageJson.data.document_data[comp.dataQuery.replace('#', '')]
        return _.get(data, 'widgetId') === WIDGET_ID || _.toString(_.get(data, 'applicationId')) === _.toString(applicationId)
    }).map(comp => ({
        comp,
        data: pageJson.data.document_data[comp.dataQuery.replace('#', '')]
    })).value()
}

function isAppPermissionsRevoked(appData) {
    return _.get(appData, 'permissions.revoked') === true
}

function fixMissingWixChatTPAOnMasterPageMobile(pageJson, clientSpecMap) {
    const wixChatAppData = _.find(clientSpecMap, {appDefinitionId: WIX_CHAT_APPDEF_ID})
    const isWidgetAlreadyAdded = getWidgetsCompAndData(pageJson).length > 0
    const shouldAddWixChatTPA = pageJson && pageJson.structure.id === MASTER_PAGE_ID &&
        wixChatAppData && !_.get(wixChatAppData, 'permissions.revoked') &&
        isWidgetAlreadyAdded &&
        hasWixChatTPAInPageStructure(pageJson.data, pageJson.structure.children, wixChatAppData) &&
        !hasWixChatTPAInPageStructure(pageJson.data, pageJson.structure.mobileComponents, wixChatAppData)

    if (shouldAddWixChatTPA) {
        const desktopComp = getWixChatComponentFromStructure(pageJson.data.document_data, pageJson.structure.children, wixChatAppData)
        addWixChatTPAGluedWidgetToMobileStructure(pageJson, desktopComp)
    }
}

function hasWixChatTPAInPageStructure(pageData, compsInStructure, appData) {
    return !!getWixChatComponentFromStructure(pageData.document_data, compsInStructure, appData)
}

function getWixChatComponentFromStructure(documentData, compsInStructure, appData) {
    let desktopChildren = [].concat(compsInStructure)
    while (!_.isEmpty(desktopChildren)) {
        const component = desktopChildren.shift()
        if (component.componentType === TPA_GLUED_WIDGET_COMP_TYPE) {
            const compData = documentData[removeHashIfExists(component.dataQuery)]
            if (Number(compData.applicationId) === Number(appData.applicationId)) {
                return component
            }
        }
        if (!_.isEmpty(component.components)) {
            desktopChildren = desktopChildren.concat(component.components)
        }
    }
    return null
}

function removeHashIfExists(value) {
    return value && value[0] === '#' ? value.substr(1) : value
}

function addWixChatTPAGluedWidgetToMobileStructure(pageJson, desktopComp) {
    const mobileComps = pageJson.structure.mobileComponents
    const footer = _.find(mobileComps, {id: 'SITE_FOOTER'})
    const dataItemQuery = desktopComp.dataQuery
    const propertyItemQuery = desktopComp.propertyQuery
    const styleId = desktopComp.styleId
    if (footer && _.isArray(footer.components)) {
        footer.components.push(getWixChatGluedTPAStructure(desktopComp.id, dataItemQuery, propertyItemQuery, styleId))
    } else {
        // landing pages
        mobileComps.push(getWixChatGluedTPAStructure(desktopComp.id, dataItemQuery, propertyItemQuery, styleId))
    }
}

module.exports = {
    /*
     * Add the wix chat app to the site if exists in clientSpecMap
     */
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        const clientSpecMap = _.get(magicObject, 'clientSpecMap')
        const uniqueIdGenerator = magicObject.dataFixerUtils.uniqueIdGenerator
        if (pageJson.structure.id === 'masterPage') {
            addWixChatToDesktopStructure(clientSpecMap, pageJson, uniqueIdGenerator)

            const isMABInstalled = _.get(magicObject, 'quickActionsMenuEnabled')
            if (_.get(pageJson, 'structure.id') === MASTER_PAGE_ID && !isMABInstalled) {
                fixMissingWixChatTPAOnMasterPageMobile(pageJson, clientSpecMap)
            }
        }
    }
}
