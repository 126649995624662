'use strict'

const _ = require('lodash')
const coreUtils = require('santa-core-utils')

const isHoverBox = comp => comp.componentType === 'wysiwyg.viewer.components.HoverBox'

const getModesData = (pageJson, comp) => {
    const definitions = _.get(comp, ['modes', 'definitions'])
    const mobileDisplayedModeId = _.get(pageJson, ['data', 'component_properties', comp.propertyQuery, 'mobileDisplayedModeId'])
    return {
        comp,
        definitions,
        mobileDisplayedModeId
    }
}
const HOVER = coreUtils.siteConstants.COMP_MODES_TYPES.HOVER

/**
 * @exports utils/dataFixer/plugins/hoverBoxPropertyDataFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        const desktopHoverBoxes = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, isHoverBox)
        const mobileHoverBoxes = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, isHoverBox)

        _.forEach(desktopHoverBoxes, (desktopHoverBox, compId) => {
            const mobileHoverBox = mobileHoverBoxes[compId]
            if (!mobileHoverBox) {
                return
            }
            const desktopModesData = getModesData(pageJson, desktopHoverBox)
            const mobileModesData = getModesData(pageJson, mobileHoverBox)

            const currentMobileMode = _.find(mobileModesData.definitions, {modeId: mobileModesData.mobileDisplayedModeId}) || {type: HOVER}
            const updatedMode = _.find(desktopModesData.definitions, {type: currentMobileMode.type}) || _.find(desktopModesData.definitions, {type: HOVER})
            const comps = [desktopModesData, mobileModesData]

            comps.forEach(({comp}) => {
                _.set(pageJson, ['data', 'component_properties', comp.propertyQuery, 'mobileDisplayedModeId'], updatedMode.modeId)
            })
            _.set(mobileHoverBox, ['modes', 'definitions'], desktopModesData.definitions)
        })
    }
}
