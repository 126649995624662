'use strict'

function addIdForGlobalImageQualityWithoutId(pageJson) {
    const qualityItemId = 'IMAGE_QUALITY'
    const dataItem = pageJson.data.document_data.undefined
    if (dataItem && dataItem.type === 'GlobalImageQuality') {
        dataItem.id = qualityItemId
        pageJson.data.document_data[qualityItemId] = dataItem
        delete pageJson.data.document_data.undefined
    }
}
module.exports = {exec: addIdForGlobalImageQualityWithoutId}