'use strict'

const _ = require('lodash')

module.exports = {
    exec(pageJson) {
        const documentData = _.get(pageJson, ['data', 'document_data'])

        if (!documentData) {
            return
        }

        _(documentData)
            .filter('posterImageRef')
            .forEach(imageRef => {
                const posterImageRefId = _.replace(imageRef.posterImageRef, '#', '')
                const posterImageRefItem = documentData[posterImageRefId]
                if (_.get(posterImageRefItem, 'uri')) {
                    posterImageRefItem.uri = _.trimStart(posterImageRefItem.uri, '/')
                }
            })
    }
}
