'use strict'

const _ = require('lodash')
const {refArray, variantRelation} = require('./dataUtils')

const SUPPORTED_STYLE_TYPES = ['TopLevelStyle', 'ComponentStyle']

const themeDataUtils = {
    /**
     * Pay attention that this might be not complete definition
     *
     * @typedef {Object} StyleDef
     * @property {string} type - style type
     * @property {string} id
     * @property {Object} metaData
     * @property {Object} style - style properties definition
     * @property {string} componentClassName
     * @property {string} pageId
     * @property {string} compId
     * @property {string} styleType
     * @property {string} skin
     */

    /**
     * @callback styleCallback
     * @param {StyleDef} style - style definition
     * @param {string} key
     * @param {Object} allStyles
     */

    /**
     * Receives array of objects and callback, applies callback only to
     * objects with supported type property
     *
     * @param {Object} styles
     * @param {styleCallback} cb
     * @returns {void}
     */
    forEachStyle(styles, cb) {
        _.forEach(styles, (style, key, allStyles) => {
            if (themeDataUtils.isSupportedStyleData(style)) {
                cb(style, key, allStyles)
            }
        })
    },

    /**
     * Runs recursively on style definition which id is provided
     * Goes deep through reference and variants data, getting all connected styles
     * and applying cb to actual style definitions {@see SUPPORTED_STYLE_TYPES}
     *
     * IMPORTANT: does not copy objects any changes will mutate original styles object
     *
     * @param {Object} styles
     * @param {string} styleId
     * @param {styleCallback} cb
     * @returns {void}
     */
    forEachComponentStyle(styles, styleId, cb) {
        const compStyleObj = styles[styleId]

        if (!compStyleObj || !cb) {
            return
        }

        if (themeDataUtils.isSupportedStyleData(compStyleObj)) {
            cb(compStyleObj, styleId, styles)
        }

        if (refArray.isRefArray(compStyleObj)) {
            const references = refArray.extractValuesWithoutHash(compStyleObj)

            references.forEach(reference => {
                themeDataUtils.forEachComponentStyle(styles, reference, cb)
            })
        }

        if (variantRelation.isVariantRelation(compStyleObj)) {
            const compVariantStyleId = compStyleObj.to

            if (!compVariantStyleId) {
                return
            }

            themeDataUtils.forEachComponentStyle(styles, compVariantStyleId, cb)
        }
    },

    /**
     * Checks if current style has type of actual style data and not reference etc.
     * @param {Object} styleData
     * @returns {boolean}
     */
    isSupportedStyleData(styleData) {
        return SUPPORTED_STYLE_TYPES.includes(styleData.type)
    },

    /**
     * Returns first style data from ref array or null
     * Covers case if styleId points to style definition
     *
     * @param {Object} styleData
     * @param {string} styleId - points to refArray
     * @returns {StyleDef|null}
     */
    getDefaultStyleByRef(styleData, styleId) {
        const referenceDefinition = styleData[styleId]

        if (themeDataUtils.isSupportedStyleData(referenceDefinition)) {
            return referenceDefinition
        }

        const references = refArray.extractValuesWithoutHash(referenceDefinition)

        let styleDef = null
        let index = 0

        for (index; index < references.length; ++index) {
            const styleRef = styleData[references[index]]

            if (themeDataUtils.isSupportedStyleData(styleRef)) {
                styleDef = styleRef
                break
            }
        }

        return styleDef
    },

    /**
     *
     * @param {Object} styleData
     * @param {string} styleId
     * @returns {StyleDef[]}
     */
    getAllStylesByRef(styleData, styleId) {
        const result = []

        themeDataUtils.forEachComponentStyle(styleData, styleId, styleDef => result.push(styleDef))

        return result
    }
}

module.exports = themeDataUtils
